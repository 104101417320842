<template>
  <ion-page class="page m-0">
    <!-- ion-content -->
    <div class="bg-transparent">
      <div class="title p-0">My Achievements</div>
      <div class="h-100 pb-3 mx-0 mt-3">
        <div class="d-flex gridding">
          <AchievementCard
            v-for="(collectible, index) of userItems"
            :key="index"
            class="m-0"
            :collectible="collectible"
            @click="showModal(collectible)"
          />
        </div>
      </div>
    </div>
    <collectible-card-modal
      :is-open="isCollectibleCardOpen"
      :collectible="activeCollectible"
      @dismissModal="isCollectibleCardOpen = false"
    />
  </ion-page>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Paging, Collectible } from '@/shared/types/static-types';
import { getOwnInventory } from '@/shared/actions/users';
import { getNextPage } from '@/shared/helpers/pagination';
import CollectibleCardModal from '@/shared/components/CollectibleCardModal.vue';
import { authStore } from '@/shared/pinia-store/auth';
import AchievementCard from '@/shared/components/storage/AchievementCard.vue';

@Options({
  name: 'InventoryPage',
  components: {
    CollectibleCardModal,
    AchievementCard,
  },
})
export default class InventoryPage extends Vue {
  public get user() {
    const { user } = authStore();
    return user.value;
  }

  public isLoading = false;

  public userItems: any = []; // {};
  public itemsPaging: Paging | null = null;
  public pageSize = 100;
  public isCollectibleCardOpen = false;
  public activeCollectible: Collectible | null = null;

  public async mounted() {
    // const { results, ...paging } = await getOwnInventory(20);
    // this.userItems = results;
    // this.itemsPaging = paging;
    this.userItems = await getOwnInventory();
  }

  public showModal(collectible: Collectible) {
    this.activeCollectible = collectible;
    this.isCollectibleCardOpen = true;
  }

  public async requestLoadMore() {
    try {
      const { results, ...paging } = await getNextPage(this.itemsPaging!);
      this.userItems = this.userItems.concat(results);
      this.itemsPaging = paging;
    } catch (e) {}
  }
}
</script>
<style lang="sass" scoped>
.gridding
  flex-wrap: wrap
  gap: 8px
.page
  margin: 1rem
.title
  font-size: 20px
  font-weight: bold
  color: #214163
</style>
